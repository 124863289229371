import React, { Component } from "react";

export default class Footers extends Component {
  render() {
    const demo = (e) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = "http://demo.minvoice.com.vn/";
      a.target = "_blank";
      a.click();
      document.body.removeChild(a);
    };

    const _call = (e) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = "tel:0901801816";
      a.click();
      document.body.removeChild(a);
    };
    var d = new Date();
    var n = d.getFullYear();
    return (
      <div>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="fix-ul">
                  <button className="btn btn-success rounded-pill" onClick={_call}>
                    HOTLINE : 0911.119900
                  </button>
                  
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-contact">
                    <span className="fix-span-footer">CÔNG TY TNHH MÃNH LONG PHÁT</span>
                    <span>O.50, Đồng Khởi, KP.3, P.Tam Hòa, Biên Hòa – Đồng Nai.</span>
                    <span className="fix-br">(0251)-730.66.88</span>
                  </div>
                  <div className="footer-contact">
                    <span className="fix-span-footer">CHI NHÁNH TẠI VŨNG TÀU</span>
                    <span> Số 811/21/6 Bình Giã, P.10, TP.Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu.</span>
                    <span className="fix-br">(0254)-730.66.68</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-contact">
                    <span className="fix-span-footer">CHI NHÁNH TẠI BÌNH DƯƠNG</span>
                    <span>Số 58 Đường ĐX 42, KP.5, Phường Phú Mỹ. TP.Thủ Dầu Một, T.Bình Dương.</span>
                    <span className="fix-br">(0274)-730.88.66</span>
                  </div>
                  <div className="footer-contact">
                    <span className="fix-span-footer"> www.ivat.vn | info@ivat.vn</span>
                    <span className="fix-br">Từ 8h đến 17h hằng ngày (trừ CN và ngày Lễ)</span>
                  </div>
                 
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-contact">
                    <span className="fix-span-footer">THÔNG TIN THANH TOÁN</span>
                    <span className="fix-br">Chủ TK: CÔNG TY TNHH MÃNH LONG PHÁT</span>
                  </div>
                  <div className="footer-contact">
                    <span className="fix-span-footer">Số TK: 0407 0407 000 8686</span>
                    <span className="fix-br">Mở tại: Ngân hàng HDBank - CN Đồng Nai</span> 
                   
                    <span className="fix-span-footer">Số TK: 7060 5525 400 0001</span>
                    <span className="fix-br">Mở tại: Ngân hàng Nam Á - CN Đồng Nai</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 footer-contact">
                  
                  <div>
                    <span className="fix-span-footer2">Kết nối với chúng tôi</span>
                    <span className="text-center text-md-right pt-3 pt-md-0">
                      <a
                        href="https://www.facebook.com/hoadondientudn/"
                        className="facebook"
                        target="_blank"
                      >
                        <img src="./LibCustom/img/Facebook.png"></img>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/manh-long-phat"
                        className="youtube p-4"
                        target="_blank"
                      >
                        <img src="./LibCustom/img/linkedin.png"></img>
                      </a>
                    </span>
                  </div>

                </div>
              </div>
              <div className="row ">
                <p className="span-footer text-white">
                  Copyrights © {n} Phần mềm Hóa đơn điện tử <strong><span>
                    M-Invoice
                  </span></strong>. All Rights Reserved.

                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
